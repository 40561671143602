import axios from "axios";

export const getData = (serviceUrl, successCallback, errorCallback) => {
    axios.get(serviceUrl).then(resp => {
        successCallback(resp);
    }).catch(error => {
        errorCallback(error);
    });

}

export const postData = (serviceUrl, data, successCallback, errorCallback) => {
    axios.post(serviceUrl, data).then(resp => {
        successCallback(resp);
    }).catch(error => {
        errorCallback(error);
    });
}
