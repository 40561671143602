import React from 'react'
import './aboutus.scss'
import { PageHeaderComponent } from '../../components/page-header'
import { AboutUsContentComponent } from '../../components/aboutus-content'
import img1 from '../../assets/images/about-us/1.jpg'
import img2 from '../../assets/images/about-us/2.jpeg'
import img3 from '../../assets/images/about-us/3.jpg'
import img4 from '../../assets/images/about-us/4.JPG'
import imgB1 from '../../assets/images/about-us/B1.jpeg'
import imgB2 from '../../assets/images/about-us/B2.jpeg'
import imgRedi from '../../assets/images/about-us/Redi.jpeg'
import imgVagni from '../../assets/images/about-us/Vangani.jpeg'
export const AboutUsLayout = () => {

    const facilityContent = <div>
            <h5>Our Services & Amenities</h5>
            <ul>
        <li>Imparting Quality Education from K.G to P.G</li>
            <li>State of Art Infrastructure </li>
            <li>Qualified & Professional Teaching Staff</li>
            <li>Smart Classrooms  </li>
            <li>Audio – Visual Classrooms</li>
            <li>Library with E – Library facility for students</li>
            <li>Well equipped Computer Labs  </li>
            <li>Science & Biotech Labs </li>
            <li>Counselling Sessions for Students & Parents </li>
            <li>Skilled based teaching method for students </li>
            <li>Sports Encouragement – District, State & National matches</li>
            <li>Dedicated Sports Ground & Sports Academy for Students </li>
            <li>Encouragement in Arts – Music, Dance & Singing through Internal Academy </li>
            <li>Scholarships </li>
            <li>Participation in various Competitions</li>
            <li>Industrial Visits</li>
            <li>Ad-on Career Oriented Courses</li>
            <li>Summer Projects </li>
            <li>Career Guidance & Workshops</li>
            <li>Night Schools & Colleges facility for working students </li>
            <li>Distance Learning facility for students  </li>
            <li>Placement & Internship Assistance </li>
    </ul>
        </div>
        

    const about1 = <div>
        The vision of VidyaVikas Education Society, Mumbai is “To be an accredited, autonomous and proactive Centre for excellence. This combines knowledge with innovation leading to all-round development of students, especially belonging to the underprivileged section of the society in their chosen area of specialization”. Our mission is “To reflect the vibrations of social aspirations in education”.
        <br/>
        The founders of this institution planted a sapling of education in 1962 in the form of a Night School in Pantanagar, Ghatkopar. With sincere efforts and noble motives of the Management the small plant has now grown up into a huge tree of knowledge in the span of 61 years (1962 - 2023) spreading it’s branches in urban, semi urban as well as rural areas like Vangani in Thane District and Redi, TalukaVengurla of Sindhdurgh District. The institution has 18 branches operating in day and night sessions. Four branches of school and colleges of Swami Shamanand Education Society which is situated at Ghatkopar, are operative in day and night sessions. Both the institution has been bestowing quality education to about 11,357 students of all categories, especially to the working class community for their development and betterment. This is being done under the able guidance of 455 dedicated Teaching and Non Teaching staff.
    </div>

    const data = [
        {
            id: 1,
            heading: 'VIDYA VIKAS EDUCATION SOCIETY, MUMBAI',
            image: imgB1,
            content: about1
        },
        {
            id: 2,
            heading: 'DRIVING FORCE BEHIND THE INSTITUTION',
            image: imgB2,
            content: 'Institution\'s Founder Trustees Hon\'ble Shri. P. M. Raut Sir ( Chairman ) and Hon\'ble ( Late ) Smt. Vidya P. Raut Madam ( Ex - C.E.O ) both have been awarded National Award for Teachers in the year 1994 & 2001 respectively by the then Hon\'ble President of India Dr. Shankar Dayal Sharma and Dr. A. P. J. Abdul Kalam. Both our founders are the strong driving force behind our institution which has encouraged other staff members and teachers to work hard and achieve State Level and National Level Awards as well.'
        },
        /*{
            id: 3,
            heading: '',
            image: img3,
            content: 'Counseling sessions are regularly held for the development of students, the result of which is reflected in the fact that about 100 students of Std.IV and Std VII achieve scholarship.Another proof of the counseling efforts is seen through the achievement of 95 % to 100 % results by Secondary Sections every year.Institutions foremost sections are of Shishu, Primary & Secondary(Marathi & English) mediums and Junior College both aided and unaided sections.Management takes complete care of the best to be delivered to the students, as well as of the teachers and staff who indeed are responsible to deliver the best to the students.Management not only monitors the quality of education but it also classifies activities, due to which interest is created within the student in Reading and gradually the asset of reading is converted in research habits.Besides creating an academic intellectual our management, our institution believes in crafting a skillful personality in every student.Primary & Secondary being base of every student, the Management with the help of the Teachers skillfully drafts yearly academic schedule in which every student can devote ample of time in Arts and Sports as per his / her individual choice besides his / her regular studies.'
        },*/
        {
            id: 3,
            heading: 'OUR INSTITUTION HAS ADAPTED ERP SYSTEM & OUR WEBSITE IS ERP BASED DYNAMIC WEBSITE',
            image: img2,
            content: facilityContent
        },
        {
            id: 4,
            heading: '',
            image: imgVagni,
            content: 'Vangani School & College Complex'
        },
        {
            id: 5,
            heading: '',
            image: imgRedi,
            content: 'Redi Village School Complex'
        },
    ]


    return (
        <div className='container'>
            <PageHeaderComponent title='About Us' />
            {
                data.map((item, i) => {
                    return <AboutUsContentComponent data={item} key={i} />
                })
            }


        </div>
    )
}
