import React from 'react'
import { NoticesLayout } from '../../layouts/notices'

export const NoticesPage = () => {
    return (
        <div>
            <NoticesLayout />
        </div>
    )
}
