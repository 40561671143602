import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../api/AxisConfig';
import { getURL } from '../../../../../api/URL';
import parse from 'html-react-parser';

import './hm-principal.layout.scss'

export const HMPrincipalLayout = ({ institute_id }) => {

    const [hmData, setHmData] = useState({});

    useEffect(() => {

        const params = [institute_id];
        getData(
            getURL("GET_HM_PRINCIPAL", "HM_PRINCIPAL", params),
            (resp) => {
                if (resp.status == 200) {
                    setHmData(resp.data.data[0]);
                }
            },
            (error) => {
                console.error(error);
            })
    }, [])

    if (!hmData) {
        return <div> No Data Available</div>
    }


    return (
        <div className='hm-principal container'>
            <div className='col-sm-12 hm-border'>
                <div className='row'>
                    <div className='col-offset-3'>
                        <img src={hmData.avtar_url} className='hm-profile-pic' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-offset-3'>
                        <hr />
                        <h5 className='name-border'>{hmData.name}</h5>
                        <hr />
                        <h6>{hmData.designation}</h6>
                        <p>{hmData.education}</p>
                    </div>
                    <div className='col-sm-12 text-data'>
                        <hr />
                        <p>{
                            (typeof hmData.info) == 'string' ? parse(hmData.info) : hmData.info
                        }</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
