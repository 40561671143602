import React from 'react'
import './facility-infra.component.scss'
import { Image } from 'primereact/image';
import Moment from 'moment';

export const FacilityInfrastructureComponent = ({ data }) => {
    return (
        <div className='col-sm-6 facility-infra'>
            <div className='card'>
                <div className="my-card">
                    <div className="my-card-image">
                        <Image src={data.avtar_url} alt="My Image" />
                        <div className="my-card-caption">
                            <h6>{data.caption}</h6>
                        </div>
                    </div>
                    <div >
                        <h6 className='create-dt'>
                            {Moment(data.created_at).format('DD/MM/yyyy')}</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}
