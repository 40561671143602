import React from 'react'
import './admission.layout.scss'
import { PageHeaderComponent } from '../../components/page-header'
import { AdmissionPageComponent } from '../../components/admission-page/admission-page.component'

export const AdmissionLayout = () => {
    return (
        <div className='container'>
            <div className='row'>
                <PageHeaderComponent title='Admission' />
            </div>
            <div className='row'>
                <AdmissionPageComponent />
            </div>
        </div>
    )
}
