import React, { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import './grievances-form.scss'
import { postData, getData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'
export const GriveancesFormComponent = () => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [field, setField] = useState();
    const [institutes, setInstitutes] = useState();
    const [submitted, setSubmit] = useState(false);
    const dataFetchedRef = useRef(false)

    useEffect(() => {

        if (dataFetchedRef.current) return;
            dataFetchedRef.current = true
        fetchInstitutes()

    }, []);

    const onSubmit = (data) => {
        setField(data)

        const sendData = {
            name: data.firstName,
            mname: data.middlename,
            dob: data.dob,
            type: data.issueType,
            enrollment: data.enrollmentNumber,
            mobile: data.mobile,
            email: data.email,
            message: data.message,
            "tid": "1",
            institute_id: data.institute_id

        }

        postData(getURL("CREATE_GRIEVANCE", "GRIEVANCES"), sendData, (response) => {
            reset();
            setSubmit(true)
        }, (error) => {
            console.log(error);
        });

    }

    const fetchInstitutes = () => {
        getData(getURL("GET_ALL_INSTITUTIONS", "INSTITUTIONS"), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                setInstitutes(resp.data.data);
                console.log(institutes)
            }
        }, (error) => {
            console.log(error);
        });
    }

    const optionMap = [
        { label: 'Select a issue type', id: 1 },
        { label: 'Degree', id: 2 },
        { label: 'Migration/Consolated Marksheet/Transcript/Provisional Degree', id: 3 },
        { label: 'Verification', id: 4 },
        { label: 'Marksheet', id: 5 },
        { label: 'Result Issue', id: 6 },
        { label: 'Other', id: 7 }
    ]

    return (
        <div className="grievance-form">
            <div className="card">
                <form className="register-form" onSubmit={handleSubmit(onSubmit)}>

                    {submitted ? <div className="success-message">Grievance submitted !</div> : null}

                    <span>{errors.firstName?.message}</span>

                    <input
                        id="first-name"
                        className="form-field"
                        type="text"
                        placeholder="First Name"
                        {...register('firstName', { required: "First Name is required!" })}
                    />

                    <span>{errors.middlename?.message}</span>

                    <input
                        id="middle-name"
                        className="form-field"
                        type="text"
                        placeholder="Last Name"
                        {...register('middlename', { required: "Last Name is required!" })}
                    />



                    <span>{errors.dob?.message}</span>

                    <input
                        id="dob"
                        className="form-field"
                        type="date"
                        placeholder="Date of birth"
                        {...register('dob', { required: "Date of birth is required!" })}
                    />

                    <span>{errors.email?.message}</span>

                    <input
                        id="email"
                        className="form-field"
                        type="email"
                        placeholder="Email"
                        {...register('email', { required: "Email is required!", pattern: { value: /^\S+@\S+$/i, message: "Invalid email" } })}

                    />

                    <span>{errors.mobile?.message}</span>

                    <input
                        id="mobile"
                        className="form-field"
                        type="tel"
                        placeholder="Mobile Number"
                        {...register('mobile', { required: "Mobile Number is required!", pattern: { value: /^[0-9]{10}$/, message: "Invalid Mobile Number" } })}

                    />

                    <span>{errors.institute?.message}</span>

                    <select id="institute"  {...register("institute_id", { required: "Institute is required!" })} className="form-field">
                        {
                            institutes?.map((item) => {
                                return <option value={item.id} key={item.id}>{item.name} </option>
                            })
                        }
                    </select>

                    <input
                        id="enrollmentNumber"
                        className="form-field"
                        type="tel"
                        placeholder="Enrollment Number"
                        {...register('enrollmentNumber')}

                    />

                    <span>{errors.issueType?.message}</span>

                    <select id="issueType"  {...register("issueType", { required: "Issue related department is required!" })} className="form-field">
                        {
                            optionMap.map((item) => {
                                if (item.id === 1) {
                                    return <option value="" key={item.id}>{item.label} </option>
                                } else {
                                    return <option value={item.label} key={item.id}>{item.label} </option>
                                }

                            })
                        }
                    </select>


                    <span>{errors.message?.message}</span>

                    <textarea
                        id="message"
                        className="form-field"
                        placeholder="Comments" rows="4"
                        {...register('message', { required: "Please provide comments" })}

                    ></textarea>

                    <button className="form-field submit" type="submit">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}
