import React, { useState } from 'react'

import './trustee.scss'
export const TrusteeProfile = ({ data }) => {

    const [isTall, setIsTall] = useState(false);

    const handleClick = () => {
        setIsTall(!isTall);
    }

    return (
        <div className="trustee-profile col-sm-12 col-lg-3">
            <div className={isTall ? "card shadow h-100" : "card shadow h-100"}>
                {
                    (data.avtar_url === null || data.avtar_url == '') ? <img src="http://vves.org.in/assets/uploads/1647336566profile%20(1).png" className="profile double-dotted-border" alt="profile" /> : <img src={data.avtar_url} className="profile double-dotted-border" alt="profile" />
                }

                <div className="card-body">
                    <h6 className="card-title">{data.name}</h6>
                    <p className="card-text">{data.designation}</p>
                    <p className="card-text">{data.position}</p>

                    {isTall ? <><hr />
                        <p className="card-text">{data.info}</p>
                        <br />
                        <p className="card-text">{data.more_info}</p></> : <></>}

                    {data.info.length > 0 || data.more_info.length > 0 ? <button onClick={() => handleClick()} class="btn btn-primary">
                        {isTall ? "Show Less" : "Show More"}
                    </button> : <></>}

                </div>
            </div>
        </div>
    )
}
