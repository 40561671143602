
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { DataView } from 'primereact/dataview';
import { ImageCollageComponent } from '../../../components/image-collage';
import './gallery-grid.scss'
import { getData } from '../../../api/AxisConfig';
import { getURL } from '../../../api/URL';

export const GalleryGridLayout = ({ institute_id, type, details }) => {

    //const [collage, setCollage] = useState([]);

    const [data, setData] = useState([]);
    const isPageVisited = useRef(false);

    useEffect(() => {
        if (isPageVisited.current) return;
        isPageVisited.current = true;

        const params = [institute_id];


        getData(
            getURL("GET_GALLERY_IMAGES", "GALLERY", params),
            resp => {
                setData(resp);
            },

            error => {
                console.log(error);
            }
        );


    }, [institute_id]);

    const processedData = useMemo(() => {
        let year_data = [];
        let years = {}

        if (data.data != null) {
            data.data.data.map(image => {
                years[image.year_code] = [];
            });

            for (let key in years) {
                let tempImage = [];
                let tempYear = { 'year': key };
                data.data.data.map(
                    item => {
                        if (key == item.year_code) {
                            tempImage.push({ "imageSrc": item.avtar_url, caption: item.caption });
                        }
                    })

                tempYear["imageData"] = tempImage;
                year_data.push(tempYear);
            }
        }

        return year_data;
    }, [data]);

    if (!data) {
        return <div>Loading data...</div>
    }

    const gridItem = (yearData) => {
        console.log("inside gridItem");

        return (
            <ImageCollageComponent data={yearData} institute_id={institute_id} />
        );
    };


    const itemTemplate = (yearData) => {
        console.log("inside itemTemplate");
        if (!yearData) {
            return;
        }
        return gridItem(yearData);
    };


    return (
        <div className="gallery-grid container">
            <div className="col-sm-12">
                {
                    (institute_id != 0 && type != 'section') ? <div className="container1 container">
                        <h1><strong className="bold">{details.name}
                            <span className="address-txt"><br />{details.address}</span>
                        </strong> </h1>
                    </div> : ""
                }

                <div className="row">
                    <DataView value={processedData} itemTemplate={itemTemplate} layout="grid" paginator rows={6} lazy />
                </div>
            </div>
        </div>
    )
}


