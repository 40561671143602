import React from 'react'
import { HomeLayout } from '../../layouts/home/home.layout'

export const HomePage = () => {
    return (
        <div>
            <HomeLayout />
        </div>
    )
}
