import React from 'react'
import { GrievancesLayout } from '../../layouts/griveances'

export const GrievancesPage = () => {
    return (
        <div>
            <GrievancesLayout />
        </div>
    )
}
