import { API_INFO } from "./APIinfo";
import { SERVICE_URL } from "./ServiceUrl";

export const getURL = (URL, PAGE_TITLE, PARAMS = []) => {

    let formedUrl = API_INFO.BASE_URL.concat(API_INFO.PRFIX).concat(API_INFO.VERSION);
    if (PAGE_TITLE != "") {
        formedUrl = formedUrl.concat(SERVICE_URL[PAGE_TITLE][URL]);
    } else {
        formedUrl = formedUrl.concat(URL);
    }

    PARAMS.map(item => {
        formedUrl = formedUrl.concat("/").concat(item);
    })

    return formedUrl;
}


