import React from "react";
import { Link } from "react-router-dom";
import "./institutions-item.scss";
import CollegeLogo from "../../assets/images/college.png";

export const InstitutionsItemComponent = ({ item }) => {
  return (
    <div className="institutions-item col-sm-4 single-institue">
      <Link to={`/institutions/${item.id}`} style={{ textDecoration: "none" }}>
        <div className="shadow-lg p-3  bg-orange rounded">
          <div className="college-logo">
            <img src={item.url} />
          </div>
          <div className="container1 container">
            <h1>
              <strong className="bold">{item.name}</strong>{" "}
            </h1>
          </div>
          <span className="address-txt">
            <br />
            <i className="pi pi-map-marker"></i> {item.address}
          </span>
        </div>
      </Link>
    </div>
  );
};
