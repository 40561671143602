import React, { useEffect, useState } from 'react'
import './school-facilities.layout.scss'
import { getData } from '../../../../../api/AxisConfig';
import { getURL } from '../../../../../api/URL';
import { useRef } from 'react';
import { FacilityInfrastructureComponent } from '../../../../../components/facility-infra/facility-infra.component';


export const SchoolFacilitiesLayout = ({ institute_id }) => {

    const [data, setData] = useState([]);
    const isPageAlreadyLoaded = useRef(false);

    useEffect(() => {

        if (isPageAlreadyLoaded.current) return;
        isPageAlreadyLoaded.current = true

        const params = [institute_id];

        getData(
            getURL("GET_FACILITIES", "SCHOOL_FACILITIES_OFFERED", params),
            (resp) => {
                if (resp.status == 200) {
                    console.log(resp.data.data);
                    setData(resp.data.data);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    });

    if (!data) {
        return <div>Data not available</div>
    }

    return (
        <div className='school-infra'>
            <div className='col-sm-12'>
                <div className='row'>
                    {
                        data.map(item => {
                            return <FacilityInfrastructureComponent data={item} key={item.id} />
                        })
                    }

                </div>
            </div>
        </div>
    )
}

