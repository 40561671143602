import React from 'react'
import { Link } from 'react-router-dom';
import Moment from 'moment';
import './notice.scss'
import AnnounmentLogo from '../../assets/images/anouncment_icon.png';

export const NoticeItemComponent = ({ notification }) => {
    return (
        <div className='notice-item'>
            <div className='card'>
                <div className='col-sm-12'>
                    <div className='row'>
                        <div className='col-sm-1'>
                            <img src={AnnounmentLogo} className='ann-icon' />
                        </div>
                        <div className='col-sm-4'>
                            <h5>{notification.category}</h5>
                            <h6>{notification.title}</h6>
                            <p>{notification.description}</p>
                            {notification.file_url !== '' ? <Link to={notification.file_url} target="_blank" download>Click here for view</Link> : <></>}

                        </div>
                        <div className='col-sm-7'>
                            <div className='create-dt'>
                                {Moment(notification.created_at).format('DD/MM/yyyy')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
