import React from 'react'
import './admission.page.scss'
import { AdmissionLayout } from '../../layouts/admission/admission.layout'

export const AdmissionPage = () => {
    return (
        <div>
            <AdmissionLayout />
        </div>
    )
}
