import React from 'react'
import { HeaderTopComponent } from '../../components/header-top';
import { NavigationBarComponent } from '../../components/navigation-bar';

export const HeaderLayout = () => {
    return (
        <>
            <HeaderTopComponent />
            <NavigationBarComponent />
        </>
    )
}