import React, { useEffect, useState, useRef } from 'react'
import './gallery-main.layout.scss'
import { getData } from '../../../api/AxisConfig';
import { getURL } from '../../../api/URL';
import { GalleryGridLayout } from '../gallery-grid';


export const GalleryMainLayout = () => {
    const isPageLoaded = useRef(false);
    const [ins, setIns] = useState([]);


    useEffect(() => {

        if (isPageLoaded.current) return;
        isPageLoaded.current = true;

        getData(getURL("GET_ALL_INSTITUTIONS", "INSTITUTIONS"), (resp) => {
            if (resp.data.success == true) {
                setIns(resp.data.data);
            }

        }, error => {
            console.log("Error ::" + error.message);
        });


    }, []);

    return (
        <div>
            <GalleryGridLayout institute_id="0" />
            {ins.map(ins => {
                return <GalleryGridLayout institute_id={ins.id} details={ins} />
            })}
        </div>
    )
}
