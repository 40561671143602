import React from 'react'
import parse from 'html-react-parser';
import './rti.scss'
export const RTIComponent = ({ info }) => {
    return (
        <div className='col-sm-12'>
            <div className='row'>
                <div className='col-sm-2'></div>
                <div className='col-sm-8'>
                    <div className='card'>
                        {parse(info.description)}
                    </div>
                </div>
                <div className='col-sm-2'></div>
            </div>
        </div>

    )
}
