import React, { useEffect, useRef, useState } from 'react'
import { getData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'
import { MediaTalksComponent } from '../../components/media-talks-item'
import { PageHeaderComponent } from '../../components/page-header'
export const MediaTalksLayout = () => {

    const [media, setMedia] = useState([]);
    const dataFetchedRef = useRef(false)

    useEffect(() => {

        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true

        getData(getURL('GET_ALL_MEDIA_TALKS', 'MEDIA_TALKS'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setMedia(resp.data.data);
            }

        }, (err) => {
            console.log(err);
        })

    }, []);


    return (
        <div className='container media-talks-layout'>
            <PageHeaderComponent title="Media Talks" />

            <div className='col-sm-12'>
                {
                    media.map(media_item => {
                        return <MediaTalksComponent media_item={media_item} key={media_item.id} />
                    })
                }
            </div>
        </div>
    )
}
