import React from 'react'
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { Image } from 'primereact/image';
import media from '../../assets/images/media.png';
import './media-talks-item.scss'

export const MediaTalksComponent = ({ media_item }) => {
    return (
        <div className="media-talks-item">
            <div className='card'>
                <div className='col-sm-12'>
                    <div className='row'>
                        <div className='col-sm-1'>
                            <img src={media} className='ann-icon' />
                        </div>
                        <div className='col-sm-9'>
                            <h5>{media_item.title}</h5>
                            <h6>{media_item.source}</h6>
                            <p>{media_item.description}</p>
                            {(media_item.file_url !== '') ? <Image src={media_item.file_url} zoomSrc={media_item.file_url} alt="View Image" imageClassName="img-fluid img-thumbnail" preview /> : ""}
                            <p>{(media_item.url !== '') ? <Link to={media_item.url} target="_blank" >Click here for view</Link>:""}</p>

                        </div>
                        <div className='col-sm-2'>
                            <div className='create-dt'>
                                {Moment(media_item.created_at).format('DD/MM/yyyy')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
