import './App.css';
import { FooterLayout } from './layouts/footer';
import { HeaderLayout } from './layouts/header';
import { NavBarRoutes } from './routes/navbar.routes';


const App = () => {
  return (
    <div className="App">
      <HeaderLayout />
      <NavBarRoutes />
      <FooterLayout />
    </div>
  );
}

export default App;
