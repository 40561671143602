import React from 'react'
import './student-login.page.scss'
import { StudentLoginLayout } from '../../layouts/student-login/student-login.layout'

export const StudentLoginPage = () => {
    return (
        <div>
            <StudentLoginLayout />
        </div>
    )
}
