import React from 'react'
import { Link } from 'react-router-dom';
import "./navigation-bar.scss";
import { useState, useEffect } from 'react';

export const NavigationBarComponent = () => {

    const expand = "collapse navbar-collapse justify-content-center";
    const [isExpaned, setIsExpaned] = useState(false);
    const [showNavBar, setShowNavBar] = useState(expand);

    const perfromAction = () => {
        setIsExpaned(false);
        setShowNavBar(expand);
    }

    useEffect(() => {
        if (isExpaned) {
            setShowNavBar(expand + " show");
        } else {
            setShowNavBar(expand);
        }
    }, [isExpaned])

    return (
        <div className='navigation-bar'>
            <nav className="navbar navbar-expand-lg navbar-dark bg-body-tertiary sticky-top">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" aria-expanded={isExpaned} onClick={() => {
                        setIsExpaned(!isExpaned);
                    }}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={showNavBar}>
                        <ul className="navbar-nav  mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/" onClick={() => perfromAction()}>HOME</Link>
                            </li>
                            <li className="nav-item no-display">
                                <span className="nav-link bar-color">|</span>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/aboutus" onClick={() => perfromAction()}>ABOUT US</Link>
                            </li>
                            <li className="nav-item no-display">
                                <span className="nav-link bar-color">|</span>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/trustees" onClick={() => perfromAction()}>TRUSTEES</Link>
                            </li>
                            <li className="nav-item no-display">
                                <span className="nav-link bar-color">|</span>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/institutions" onClick={() => perfromAction()}>SECTIONS OF THE INSTITUTIONS</Link>
                            </li>
                            <li className="nav-item no-display">
                                <span className="nav-link bar-color">|</span>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" onClick={() => {
                                    // perfromAction();
                                    window.location = "http://swamishamanand.com/"
                                }}>SSES</Link>
                            </li>
                            <li className="nav-item no-display">
                                <span className="nav-link bar-color">|</span>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/awards" onClick={() => perfromAction()}>AWARDS</Link>
                            </li>
                            <li className="nav-item no-display">
                                <span className="nav-link bar-color" >|</span>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/gallery/all" onClick={() => perfromAction()}>GALLERY</Link>
                            </li>
                            <li className="nav-item no-display">
                                <span className="nav-link bar-color">|</span>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contactus" onClick={() => perfromAction()}>CONTACT US</Link>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav >

        </div >
    )
}

