import React from 'react'
import Moment from 'moment';
import { Link } from 'react-router-dom';
import './important-links.scss'
import navigate from '../../assets/images/navigate.png';
export const ImportantLinksComponent = ({ important_links }) => {
    return (
        <div><div className="important-links-item">
            <div className='card'>
                <div className='col-sm-12'>
                    <div className='row'>
                        <div className='col-sm-1'>
                            <img src={navigate} className='ann-icon' />
                        </div>
                        <div className='col-sm-4'>
                            <h5>{important_links.title}</h5>
                            <Link to={important_links.url} target="_blank" >Click here for view</Link>
                        </div>
                        <div className='col-sm-7'>
                            <div className='create-dt'>
                                {Moment(important_links.created_at).format('DD/MM/yyyy')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></div>
    )
}
