import React from 'react'
import { RTILayout } from '../../layouts/rti'

export const RTIPage = () => {
    return (
        <div>
            <RTILayout />
        </div>
    )
}
