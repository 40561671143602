import React from 'react'
import './header-top.scss'
import Logo from '../../assets/images/vves_logo.png'
import Azadi75Logo from '../../assets/images/Azadi_Ka_Amrit_Mahotsav_(English)_logo.svg'
import SixtyYearLogo from '../../assets/images/60th Year Logo.png'

export const HeaderTopComponent = () => {
    return (
        <div className="header-top">
            <div className="container-fluid bg-color">
                <div className="row">
                    <div className="container">
                        <div className='row'>
                            <div className='col-sm-5'>
                                <div className='col-sm-7'>
                                    <img src={Azadi75Logo} alt="Aazadi 75 years" className="img-fluid img-thumbnail aazadi-logo" />
                                </div>
                            </div>
                            <div className='col-sm-2'>
                                <img src={Logo} alt="VVES Logo" className="img-fluid img-thumbnail center-block" />
                            </div>
                            <div className='col-sm-5'>
                                <div className='col-sm-7 pull-right'>
                                    <img src={SixtyYearLogo} alt="Aazadi 75 years" className="img-fluid img-thumbnail aazadi-logo" />
                                </div>
                            </div>


                        </div>
                        <div className='row'>
                            <h2 className='site-header-title'>VIDYA VIKAS EDUCATION SOCIETY</h2>
                        </div>
                        <div className='row'>
                            <h6 className='site-header-desc'>ISO 9001 : 2008 Certified Institution</h6>
                            <h6 className='site-header-desc'>Awarded Savitiribhai Phule - Fatima Shaikh Smruti- "The Best Educational Institution Award " in 2010</h6>
                            <h6 className='site-header-desc'>Kannamwar Nagar - 2, Vikhroli (East), Mumbai - 400083</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

