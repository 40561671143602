import React, { useEffect, useState } from 'react'
import { PageHeaderComponent } from '../../components/page-header'
import { TrusteeProfile } from '../../components/trustee-profile'
import { getData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

export const TrusteesLayout = () => {

    const [trustees, setTrustees] = useState([]);

    useEffect(() => {
        getData(getURL('GET_ALL_TRUSTEES', 'TRUSTEE'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setTrustees(resp.data.data);
            }

        }, (err) => {
            console.log(err);
        })
    }, []);


    if (!trustees) {
        return <div>Loading data...</div>;
    }

    return (
        <div className='container'>
            <PageHeaderComponent title="Trustees" />
            <div className='row'>
                {
                    trustees.map(item => {
                        return <TrusteeProfile key={item.id} data={item} />
                    })
                }
            </div>

        </div>
    )
}
