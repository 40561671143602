import React from 'react'
import { MediaTalksLayout } from '../../layouts/media-talks'

export const MediaTalksPage = () => {
    return (
        <div>
            <MediaTalksLayout />
        </div>
    )
}
