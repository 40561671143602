import React from 'react'
import { Outlet } from 'react-router-dom';
import { GalleryLayout } from '../../layouts/gallery';

export const GalleryPage = () => {
    return (
        <div>
            <GalleryLayout />
            <Outlet />
        </div>
    )
}
