import React from 'react'
import { AwardsLayout } from '../../layouts/awards';

export const AwardsPage = () => {
    return (
        <div>
            <AwardsLayout />
        </div>
    )
}
