import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './admission-page.component.scss'
import { getData } from '../../api/AxisConfig';
import { getURL } from '../../api/URL'

export const AdmissionPageComponent = () => {


    /*const [admisisonLink, setAdmissionLink] = useState([
        { id: 1, institute_name: "Dr. Babasaheb Ambedkar Vidyalaya (Permanently Unaided English Medium School)", link: "http://www.google.com" },
        { id: 2, institute_name: "Vikas High School Primary Section, Marathi Medium, Vikhroli", link: "http://www.facebook.com" }

    ]);*/

    const [admisisonLink, setAdmissionLink] = useState([]);
    const isPageLoaded = useRef(false);

    useEffect(() => {
        if (isPageLoaded.current) return;
        isPageLoaded.current = true;
        fetchAll();

    }, []);

    const fetchAll = () => {
        let params = []
        params.push("category");
        params.push("ADMISSION-PORTAL");
        getData(getURL('GET', 'ADMISSION_STUDENT_PORTAL_LINKS', params), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setAdmissionLink(resp.data.data);
            }
        }, (err) => {

        })
    }


    return (
        <div className='admission'>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Institute Name</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        admisisonLink.map(item => {
                            return <tr>
                                <td scope="row">{item.id}</td>
                                <td>{item.institute}</td>
                                <td><Link to={item.url}>Apply Now</Link></td>
                            </tr>
                        })
                    }

                </tbody>
            </table>
        </div>
    )
}
