import React from 'react'
import './student-login.layout.scss'
import { PageHeaderComponent } from '../../components/page-header'
import { StudentLoginComponent } from '../../components/student-login/student-login.component'

export const StudentLoginLayout = () => {
    return (
        <div className='container'>
            <div className='row'>
                <PageHeaderComponent title="Student Login" />
            </div>
            <div className='row'>
                <StudentLoginComponent />
            </div>
        </div>

    )
}
