import React, { useEffect, useState, useRef } from 'react'

import { getData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'
import { PageHeaderComponent } from '../../components/page-header'
import './notices.scss'


import { TabView, TabPanel } from 'primereact/tabview';
import { NoticeItemComponent } from '../../components/notice-item';
import { getCurrentYear } from '../../utils/utility'

export const NoticesLayout = () => {

    const [tabs, setTabs] = useState([]);
    const [notices, setNotice] = useState([]);

    const [activeIndex, setActiveIndex] = useState(0);
    const dataFetchedRef = useRef(false);


    useEffect(() => {
        if (dataFetchedRef.current)
            return;
        dataFetchedRef.current = true;
        fetchHeaderData();
    }, []);



    if (!tabs || !notices) {
        return <div>Loading data...</div>;
    }

    const fetchHeaderData = () => {
        getData(getURL('GET_ALL_NOTICE_TYPES', 'NOTICE_TYPE'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setTabs(resp.data.data);
                const params = [];
                console.log(getCurrentYear())
                params.push(getCurrentYear())
                params.push(resp.data.data[activeIndex].notice_code);
                fetchTabDataByHead(params)
            }

        }, (err) => {
            console.log(err);
        })

    }

    const fetchTabDataByHead = (params) => {

        getData(getURL('GET_ALL_NOTICE_TYPES', 'NOTICE_TYPE', params), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setNotice(resp.data.data);
            }

        }, (err) => {
            console.log(err);
        })
    }

    const onHeaderChange = (index) => {
        const params = [];
        params.push(getCurrentYear())
        params.push(tabs[index].notice_code);
        fetchTabDataByHead(params);

    };


    return (
        <div className='container'>
            <div className='notices-layout'>
                <PageHeaderComponent title='Notices' />
                <TabView className='card' activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index); onHeaderChange(e.index) }} scrollable>
                    {
                        tabs.map((tab) => {
                            return (<TabPanel header={tab.notice_code} key={tab.id}>
                                {
                                    notices.map((notification) => {
                                        return <NoticeItemComponent notification={notification} key={notification.id} />
                                    })
                                }

                            </TabPanel>)
                        })
                    }

                </TabView>
            </div>
        </div >
    )
}
