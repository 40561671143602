import React from 'react'
import { ImportantLinksLayout } from '../../layouts/important-links'

export const ImportantLinksPage = () => {
    return (
        <div>
            <ImportantLinksLayout />
        </div>
    )
}
