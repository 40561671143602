import React from 'react'
import { PageHeaderComponent } from '../../components/page-header'
import { InstitutionsItemComponent } from '../../components/institutions-item'
import './institutions.scss'


export const InstitutionsLayout = () => {
    return (
        <div className="institutions container">
            <PageHeaderComponent title="Institutions" />
        </div >
    )
}
