import React from 'react'
import { PageHeaderComponent } from '../../components/page-header'
import './gallery.scss'

export const GalleryLayout = () => {
    return (
        <div className="gallery container">
            <PageHeaderComponent title="Gallery" />
        </div>
    )
}
