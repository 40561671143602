import React, { useEffect, useRef } from 'react'

import { InstitutionsItemComponent } from './../../../components/institutions-item/index';
import './institutions-grid.scss'
import { useState } from 'react';

import { getData } from '../../../api/AxisConfig'
import { getURL } from '../../../api/URL'

export const InsitutionsGridLayout = () => {

    const [institutes, setInstitutes] = useState([]);
    const isPageLoaded = useRef(false);

    useEffect(() => {

        if (isPageLoaded.current) return;
        isPageLoaded.current = true;


        getData(getURL("GET_ALL_INSTITUTIONS", "INSTITUTIONS"), (resp) => {
            if (resp.data.success == true) {
                setInstitutes(resp.data.data);
            }

        }, error => {
            console.log("Error ::" + error.message);
        });


    }, []);

    if (!institutes) {
        return <div>Loading data...</div>;
    }

    /*const insitutes = [
        { "title": "Dr. Babasaheb Ambedkar Vidyalaya (Permanently Unaided English Medium School)", "address": "Kannamwar nagar -2,vikroli (EAST),mumbai - 400083", id: "1" },
        { "title": " Matoshri Parvati Raut Vidyalaya, Redi", "address": "Kannamwar nagar -2,vikroli (EAST),mumbai - 400083", id: "2" },
        { "title": "Vikas Ratra Shala & Jr. College, Vikroli ( ISO 9001:2015 Certified )", "address": "Kannamwar nagar -2,vikroli (EAST),mumbai - 400083", id: "3" },
        { "title": "Vikas High School Shishu Vibhag, English and Marathi Medium", "address": "Kannamwar nagar -2,vikroli (EAST),mumbai - 400083", id: "5" },
        { "title": "Vikas High School Primary Section, Marathi Medium (Aided Schoo)", "address": "Kannamwar nagar -2,vikroli (EAST),mumbai - 400083", id: "6" },
        { "title": "Vikas High School Primary Section, English Medium (Aided School)", "address": "Kannamwar nagar -2,vikroli (EAST),mumbai - 400083", id: "7" },
        { "title": " Vikas High School Semi English and Marathi Medium(Aided School)", "address": "Kannamwar nagar -2,vikroli (EAST),mumbai - 400083", id: "8" },
        { "title": "Vikas High School & Junior College (Aided & Unaided)", "address": "Kannamwar nagar -2,vikroli (EAST),mumbai - 400083", id: "9" },
        { "title": "Vidya Vikas Education Society - Vangani (Secondary & Higher Secondary)", "address": "Kannamwar nagar -2,vikroli (EAST),mumbai - 400083", id: "10" },
        { "title": "Dr. W S Matkar Sangeet Vidyalaya (Certified by: Akhil Bhartiya Gandharva Mahavidyalay Mandal)", "address": "Kannamwar nagar -2,vikroli (EAST),mumbai - 400083", id: "11" },
    ]*/


    return (
        <div className='institutions-grid container'>
            <div className='col-sm-12'>
                <div className='row'>
                    {
                        institutes.map(item => {
                            return <InstitutionsItemComponent item={item} key={item.id} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}
