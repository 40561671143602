import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { StudentAchievmentsItemComponent } from '../../../../../components/student-achievements-item'
import { getData } from '../../../../../api/AxisConfig'
import { getURL } from '../../../../../api/URL'

import './awards-achievement.layout.scss'

export const AwardsAchievementsLayout = ({ institute_id, achievement_type }) => {


    const [selectedAcademicYear, setSelectedAcademicYear] = useState(null);
    const [academicYears, setAcademicYears] = useState([]);
    const [achievments, setAchievments] = useState([]);
    const [visitedAchivmentType, setVisitedAchivmentType] = useState(null);

    const dataFetchedRef = useRef(false);



    useEffect(() => {

        //if same type is alreadyed fetched then return immediately
        if (visitedAchivmentType == achievement_type) {
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;
        } else {
            //else since its new reuqest so continue
            dataFetchedRef.current = false;
        }


        let params = [institute_id];


        getData(getURL('GET_ACADEMIC_YEAR', 'ACADEMIC_YEARS'), (resp) => {
            setAcademicYears(resp.data.data)
            //  params.push(resp.data.data[0].year_code);

            setSelectedAcademicYear(resp.data.data[0].year_code);

            if (achievement_type == "student") {
                getData(getURL('GET_STUDENT_ACHIEVEMENT', 'ACHIEVEMENT', params), (resp2) => {
                    setAchievments(resp2.data.data)
                }, (error) => { console.log("Error :: " + error) });
            } else {
                getData(getURL('GET_TEACHER_ACHIEVEMENT', 'ACHIEVEMENT', params), (resp2) => {
                    setAchievments(resp2.data.data)
                }, (error) => { console.log("Error :: " + error) });
            }


        }, (error) => { console.log("Error :: " + error) });



    }, [achievement_type]);

    const fetchStudentAchievements = (year, institute_id) => {
        console.log("Selected Year::" + year.year_code);

        let params = [institute_id];

        if (achievement_type == "student") {
            getData(getURL('GET_STUDENT_ACHIEVEMENT', 'ACHIEVEMENT', params), (resp2) => {
                setAchievments(resp2.data.data.filter(achievment => achievment.year_code === year.year_code));
            }, (error) => { console.log("Error :: " + error) });
        } else {
            getData(getURL('GET_TEACHER_ACHIEVEMENT', 'ACHIEVEMENT', params), (resp2) => {
                setAchievments(resp2.data.data.filter(achievment => achievment.year_code === year.year_code));
            }, (error) => { console.log("Error :: " + error) });
        }

    }


    return (
        <div className='container student-achievements-layout'>
            <div className='card'>
                <div className='col-sm-12 '>
                    <div className='row'>
                        <div className='col-sm-6'>
                        </div>
                        <div className='col-sm-6 padding'>
                            <div className='float-right'>
                                <Dropdown value={selectedAcademicYear} onChange={(e) => { setSelectedAcademicYear(e.value); fetchStudentAchievements(e.value, institute_id) }} options={academicYears} optionLabel="year_code"
                                    placeholder="Academic Year" className="w-full md:w-14rem " />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12'>
                    <div className='row padding'>
                        {
                            (achievments.length === 0) ? <h5> No achievement for selected academic year</h5> :
                                achievments.map(item => {
                                    return <StudentAchievmentsItemComponent achievement={item} key={item.id} />
                                })


                        }

                    </div>
                </div>

            </div>
        </div>
    )

}
