import React from 'react'
import { AboutUsLayout } from '../../layouts/aboutus';

export const AboutUsPage = () => {
    return (
        <div>
            <AboutUsLayout />
        </div>
    )
}

