import React from 'react'
import { GriveancesFormComponent } from '../../components/grievances-form'
import { PageHeaderComponent } from '../../components/page-header'
import './grievances.scss'

export const GrievancesLayout = () => {
    return (
        <div className='grievances-layout'>
            <div className='container'>
                <PageHeaderComponent title="Grievances Form" />
                <div className='offset-sm-3 col-sm-6'>
                    <GriveancesFormComponent />
                </div>
            </div>
        </div>
    )
}
