import React from 'react'
import { StudentAchievementsLayout } from '../../layouts/student-achievments'

export const StudentAchievmentsPage = () => {
    return (
        <div>
            <StudentAchievementsLayout />
        </div>
    )
}
