import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { GalleryDetailLayout } from '../layouts/gallery/gallery-details-view';
import { GalleryMainLayout } from '../layouts/gallery/gallery-main/gallery-main.layout';
import { AboutUsPage } from '../pages/aboutus';
import { AwardsPage } from '../pages/awards';
import { ContactusPage } from '../pages/contactus';
import { GalleryPage } from '../pages/gallery';
import { HomePage } from '../pages/home';
import { InstitutionsPage } from '../pages/institutions';
import { TrusteesPage } from '../pages/trustees';
import { RTIPage } from '../pages/rti';
import { NoticesPage } from '../pages/notices';
import { MediaTalksPage } from '../pages/media-talks';
import { GrievancesPage } from '../pages/grievances';
import { ImportantLinksPage } from '../pages/important-links';
import { StudentAchievmentsPage } from '../pages/student-achievements';
import { InstitutionsLayout } from '../layouts/institutions';
import { InsitutionsGridLayout } from '../layouts/institutions/institutions-grid';
import { InstitutionDetailsViewLayout } from '../layouts/institutions/institution-details-view';
import { AdmissionPageComponent } from '../components/admission-page/admission-page.component';
import { AdmissionPage } from '../pages/admission/admission.page';
import { StudentLoginPage } from '../pages/student-login/student-login.page';

export const NavBarRoutes = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/aboutus' element={<AboutUsPage />} />
                <Route path='/trustees' element={<TrusteesPage />} />
                <Route path='/institutions' element={<InstitutionsPage />} >
                    <Route path='/institutions' element={<InsitutionsGridLayout />}></Route>
                    <Route path='/institutions/:id' element={<InstitutionDetailsViewLayout />}></Route>
                </Route>
                <Route path='/awards' element={<AwardsPage />} />
                <Route path='/gallery' element={<GalleryPage />} >
                    <Route path='/gallery/all' element={<GalleryMainLayout />} />
                    <Route path='/gallery/:year' element={<GalleryDetailLayout />} />
                    <Route path='/gallery/:year/:instituteid' element={<GalleryDetailLayout />} />
                    <Route path='/gallery/institute/:instituteid' element={<GalleryDetailLayout />} />

                </Route>
                <Route path='/contactus' element={<ContactusPage />} />

                <Route path='/rti' element={<RTIPage />} />
                <Route path='/notices' element={<NoticesPage />} />
                <Route path='/mediatalks' element={<MediaTalksPage />} />
                <Route path='/grievances' element={<GrievancesPage />} />
                <Route path='/importantlinks' element={<ImportantLinksPage />} />
                <Route path='/studentachievements' element={<StudentAchievmentsPage />} />
                <Route path='/admission' element={<AdmissionPage />} />
                <Route path="/studentlogin" element={<StudentLoginPage />} />
            </Routes>
        </>
    )
}



