import React from 'react'
import parse from 'html-react-parser';
import './award-content.scss'

export const AwardContentComponent = ({ data }) => {
    return (
        <div className="trustee-awards col-sm-4 col-lg-4">
            <div className="card shadow h-100">
                <img src={data.avtar_url} className="profile col-sm-12" alt="profile" style={{ height: "auto", maxWidth: "100%" }} />
                <div className="card-body col-sm-12">
                    <h6 className="card-title">{data.awardee_name}</h6>
                    <hr />
                    <div className="info">
                        {parse(data.content)}
                    </div>
                </div>
            </div>
        </div>
    )
}
