import React from 'react'
import './gallery-image-view.scss';

export const GalleryImageViewComponent = ({ src, alt }) => {
    return (
        <div className='gallery-image-view '>
            <div className="row">
                <div className="col-sm-12">
                    <img
                        src={src}
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt={alt}
                    />
                </div>

            </div>

        </div>
    )
}
