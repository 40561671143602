import React from 'react'
import './aboutus-content.scss'

export const AboutUsContentComponent = ({ data }) => {
    return (
        <div className="about">
            {data.heading !== '' ?
                <div className='col-md-12'>
                    <div className="row">
                        <div className='col-md-6'></div>
                        <div className='col-md-6 '><h3 className='heading-style'>{data.heading}</h3></div>

                    </div>
                    <div className="row">
                        {data.image !== '' ? <img src={data.image} alt="about" className="about-img img-fluid col col-sm-12 col-lg-6" /> : ''}
                        {data.content !== '' ?
                            <div className="about-body col-sm-12 col-lg-6">
                                <p>{data.content}</p>
                            </div>
                            : ''}
                    </div>
                </div>
                : <div className="row">
                    {data.image !== '' ? <img src={data.image} alt="about" className="about-img img-fluid col col-sm-12 col-lg-6" /> : ''}
                    {data.content !== '' ?
                        <div className="about-body col-sm-12 col-lg-6">
                            <p>{data.content}</p>
                        </div>
                        : ''}
                </div>}



        </div>
    )
}
