import React, { useState, useEffect, useRef } from "react";
import { getData } from "../../../../../api/AxisConfig";
import { getURL } from "../../../../../api/URL";

import "./pdf-viewer.layout.scss";

export const PdfViewerLayout = ({ api, endpoint, institute_id }) => {
  const [data, setData] = useState(null);
  const isPageLoaded = useRef(false);
  const [apiChange, setApiChange] = useState(null);

  console.log(api);

  useEffect(() => {
    if (isPageLoaded.current && api === apiChange) return;
    isPageLoaded.current = true;

    setApiChange(api);

    const params = ["institute", institute_id];

    getData(
      getURL(endpoint, api, params),
      (resp) => {
        if (resp.status === 200) {
          console.log(resp.data.data.file_url);
          setData(resp.data.data);
        }
      },
      (error) => {
        console.log("Error ::" + error);
      }
    );
  }, [api]);

  if (!data) {
    return <div>Page is loading...</div>;
  }

  return (
    <div className="academic-calendar conatiner">
      <div>
        <div className="title">
          <h6>{data.title}</h6>
        </div>
        <object
          width="100%"
          height="500px"
          data={data.file_url}
          type="application/pdf"
        >
          {" "}
        </object>
      </div>
    </div>
  );
};
