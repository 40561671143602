import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './student-login.component.scss'
import { getData } from '../../api/AxisConfig';
import { getURL } from '../../api/URL'

export const StudentLoginComponent = () => {
    /*const [studentPortalLink, setStudentPortalLink] = useState([
        { id: 1, institute_name: "Dr. Babasaheb Ambedkar Vidyalaya (Permanently Unaided English Medium School)", link: "http://www.google.com" },
        { id: 2, institute_name: "Vikas High School Primary Section, Marathi Medium, Vikhroli", link: "http://www.facebook.com" }

    ]);*/

    const [studentPortalLink, setStudentPortalLink] = useState([]);
    const isPageLoaded = useRef(false);


    useEffect(() => {
        if (isPageLoaded.current) return;
        isPageLoaded.current = true;
        fetchAll();

    }, []);


    const fetchAll = () => {
        let params = []
        params.push("category");
        params.push("STUDENT-PORTAL");
        getData(getURL('GET', 'ADMISSION_STUDENT_PORTAL_LINKS', params), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setStudentPortalLink(resp.data.data);
            }
        }, (err) => {

        })
    }

    return (
        <div className='admission'>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Institute Name</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        studentPortalLink.map(item => {
                            return <tr>
                                <td scope="row">{item.id}</td>
                                <td>{item.institute}</td>
                                <td><Link to={item.link}>Login</Link></td>
                            </tr>
                        })
                    }

                </tbody>
            </table>
        </div>
    )

}
