import React, { useEffect, useRef, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { StudentAchievmentsItemComponent } from '../../components/student-achievements-item'
import { PageHeaderComponent } from '../../components/page-header'
import { getData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import './student-achievments.scss'
export const StudentAchievementsLayout = () => {


    const [selectedAcademicYear, setSelectedAcademicYear] = useState(null);
    const [academicYears, setAcademicYears] = useState([]);
    const [achievments, setAchievments] = useState([]);

    const dataFetchedRef = useRef(false);



    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        let params = [];
        params.push("year");


        getData(getURL('GET_ACADEMIC_YEAR', 'ACADEMIC_YEARS'), (resp) => {
            setAcademicYears(resp.data.data)
            params.push(resp.data.data[0].year_code);
            setSelectedAcademicYear(resp.data.data[0].year_code);
            getData(getURL('GET_ALL_ACHIEVEMENT', 'STUDENT_ACHIEVEMENTS', params), (resp2) => {
                setAchievments(resp2.data.data)
            }, (error) => { console.log("Error :: " + error) });

        }, (error) => { console.log("Error :: " + error) });



    }, []);

    const fetchStudentAchievements = (year) => {
        console.log("Selected Year::" + year.year_code);

        let params = [];
        params.push("year");
        params.push(year.year_code);

        getData(getURL('GET_ALL_ACHIEVEMENT', 'STUDENT_ACHIEVEMENTS', params), (resp) => {
            setAchievments(resp.data.data.filter(achievment => achievment.year_code === year.year_code));
        }, (error) => { console.log("Error :: " + error) });


    }


    return (
        <div className='container student-achievements-layout'>
            <PageHeaderComponent title="Student Achievements" />
            <div className='card'>
                <div className='col-sm-12 '>
                    <div className='row'>
                        <div className='col-sm-6'>
                        </div>
                        <div className='col-sm-6 padding'>
                            <div className='float-right'>
                                <Dropdown value={selectedAcademicYear} onChange={(e) => { setSelectedAcademicYear(e.value); fetchStudentAchievements(e.value) }} options={academicYears} optionLabel="year_code"
                                    placeholder="Academic Year" className="w-full md:w-14rem " />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12'>
                    <div className='row padding'>
                        {
                            (achievments.length === 0) ? <h5> No achievement for selected academic year</h5> :
                                achievments.filter(item => item.is_tearcher === '0').map(item => {
                                    return <StudentAchievmentsItemComponent achievement={item} key={item.id} />
                                })


                        }

                    </div>
                </div>

            </div>
        </div>
    )
}
