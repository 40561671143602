import React, { useEffect, useRef, useState } from 'react'
import './important-links.scss'
import { getData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'
import { PageHeaderComponent } from '../../components/page-header'
import { ImportantLinksComponent } from '../../components/important-links'

export const ImportantLinksLayout = () => {


    const [importantlinks, setImportantLinks] = useState([]);
    const dataFetchedRef = useRef(false)

    useEffect(() => {

        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true

        getData(getURL('GET_ALL_IMPORTANT_LINS', 'IMPORTANT_LINKS'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                setImportantLinks(resp.data.data);
            }

        }, (err) => {
            console.log(err);
        })

    }, []);


    return (
        <div className='container important-link-layout'>
            <PageHeaderComponent title='Important Links' />

            <div className='row'>
                {
                    importantlinks.map(item => {
                        return <ImportantLinksComponent important_links={item} />
                    })
                }
            </div>
        </div>

    )
}
