import React from 'react'
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import './image-collage.scss'

export const ImageCollageComponent = ({ data, institute_id }) => {

    console.log("inside::" + data);
    console.error("institute id:" + institute_id);


    const images = data.imageData;

    return (
        <div className="image-collage col-sm-2">


            <Card>
                <table className="table table-image">
                    <tbody>
                        <tr>
                            <td className="w-25">
                                {
                                    images[0] != null ? <Image src={images[0].imageSrc} zoomSrc={images[0].imageSrc} alt="Image" imageClassName="img-fluid img-thumbnail" preview />
                                        : ''
                                }
                            </td>
                            <td className="w-25">
                                {
                                    images[1] != null ?
                                        <Image src={images[1].imageSrc} zoomSrc={images[1].imageSrc} alt="Image" imageClassName="img-fluid img-thumbnail" preview />

                                        : ''
                                }     </td>

                        </tr>
                        <tr>
                            <td className="w-25">
                                {
                                    images[1] != null ?
                                        <Image src={images[1].imageSrc} zoomSrc={images[1].imageSrc} alt="Image" imageClassName="img-fluid img-thumbnail" preview />

                                        : ''
                                }    </td>
                            <td className="w-25">
                                <div className="custom-container">
                                    {
                                        images[1] != null ? <>
                                            <img src={images[1].imageSrc} className="img-fluid img-thumbnail last-item" alt="Sheep" />
                                            <div className="centered"><Link to={`/gallery/institute/${institute_id}`}>View More</Link></div></>
                                            : ''
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <h6 className="year-txt-style">{data.year}</h6>
                </div>
            </Card>
        </div>

    )
}
