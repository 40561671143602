import React, { useState, useEffect, useRef } from 'react';
import { Galleria } from 'primereact/galleria';
import { useParams } from 'react-router-dom'
import { Dropdown } from 'primereact/dropdown';
import { DataView } from 'primereact/dataview';
import { getURL } from '../../../api/URL';
import { getData } from '../../../api/AxisConfig';
import './gallery-details.scss'
import { GalleryImageViewComponent } from '../../../components/gallery-image-view';


export const GalleryDetailLayout = () => {

    const { instituteid } = useParams();




    const [images, setImages] = useState(null);
    const [ins, setIns] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const galleria = useRef(null);
    const isVisitedPage = useRef(false)

    useEffect(() => {

        //   if (isVisitedPage.current) return;
        // isVisitedPage.current = true;

        const params = [instituteid];
        console.log("params :: " + instituteid);

        getData(getURL("GET_GALLERY_IMAGES", "GALLERY", params), (resp) => {
            setImages(resp.data.data);

        }, error => {
            console.log("Error ::" + error.message);
        });

        if (instituteid != 0) {
            getData(getURL("GET_ALL_INSTITUTIONS", "INSTITUTIONS", params), (resp) => {

                setIns(resp.data.data);


            }, error => {
                console.log("Error ::" + error.message);
            });
        }


    }, [instituteid]);

    const itemTemplate = (item) => {
        return <GalleryImageViewComponent src={item.avtar_url} alt={item.caption} type="thumbnail" />
    }

    const thumbnailTemplate = (item) => {
        return <GalleryImageViewComponent src={item.avtar_url} alt={item.caption} type="thumbnail" />

    }





    const caption = (item) => {
        return (
            <React.Fragment>
                <div className="text-xl mb-2 font-bold">{item.caption}</div>
                <p className="text-white">{item.alt}</p>
            </React.Fragment>
        );
    }

    const itemTemplateMainGrid = (item) => {

        let imgEl =
            <div className="thumbnail">
                <img src={item.avtar_url}
                    onClick={() => { setActiveIndex(images.indexOf(item)); galleria.current.show() }} />
            </div>
        return (
            <div className="col-sm-3 img-container" >
                {imgEl}
            </div>
        )

    }

    return (
        <div className='gallery-details container'>
            {instituteid != null && instituteid != 0 && ins != null ?
                <div className='col-sm-12'>
                    <div className="container1 container">
                        <h1><strong className="bold">{ins.name}
                            <span className="address-txt"><br />{ins.address}</span>
                        </strong> </h1>
                    </div>
                </div> : ''}


            <div className="col-sm-12">
                <Galleria caption={caption} ref={galleria} value={images} numVisible={7} style={{ maxWidth: '850px' }}
                    activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                    circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />

                <div className="row">
                    <DataView value={images} itemTemplate={itemTemplateMainGrid} layout='grid' paginator rows={10} />
                </div>
            </div>
        </div>
    )
}
