import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup';
import './footer-main.scss'
import Logo from '../../assets/images/footer-logo.png'
import YashoGatha from '../../assets/images/yashogatha.jpg'
import { getData } from '../../api/AxisConfig.js'
import { getURL } from '../../api/URL'



export const FooterMainComponent = () => {

    const [magzine, setMagzine] = useState({});
    const isDataFetched = useRef(false);

    useEffect(() => {
        if (isDataFetched.current) return;
        isDataFetched.current = true;

        getData(getURL('GET_MAGZINE', 'MAGZINE'), respons => {
            setMagzine(respons.data.data[0])
        }, error => {
            console.log(error);
        });

    }, []);



    return (
        <div className="footer-main">
            <div className="container">
                <div className="row" >
                    <div className="col-5-sm"></div>
                    <div className="col-2-sm style-footer-logo"> <img src={Logo} alt="Different Pic need to add" /></div>
                    <div className="col-5-sm"></div>

                </div>
                <div className="row" >
                    <h4 className='color-aqua gyanmaydip'>|| प्रज्वलितो ज्ञानमयोदीप ||</h4>
                </div>
                <div className="row">
                </div>
                <div className="row" >
                    <div className="col-sm-3"></div>
                    <div className="col-sm-3">
                        <h6>TILL DATE PASS OUT STUDENTS</h6>
                        <h1 className='color-aqua'>
                            <CountUp start={0} end={130800} duration={2.75} />
                        </h1>
                    </div>
                    <div className="col-sm-3">
                        <h6>PRESENT STUDENTS STRENGTH</h6>
                        <h1 className='color-aqua'>
                            <CountUp start={0} end={11375} duration={2.75} />
                        </h1>
                    </div>
                    <div className="col-sm-3"></div>
                </div>
                <div className="row" >
                    <div className="col-sm-4">
                        <h6> Follow us on </h6>
                        <Link to="https://www.facebook.com/vves.sses" className="fa fa-facebook" />
                        <Link to="/" className="fa fa-twitter" />
                        <Link to="https://www.youtube.com/@vidyavikaseducationsociety5892/featured" className="fa fa-youtube" />
                        <Link to="https://www.instagram.com/vves.sses/" className="fa fa-instagram" />
                    </div>
                    <div className="col-sm-4">
                        <h6> &nbsp; </h6>
                        <div className="d-flex justify-content-center">
                            <Link to="/studentlogin" className="btn btn-danger student-login-alumini align-middle" >STUDENT LOGIN</Link>
                            <Link to="http://alumni.vves.org.in" className="btn btn-danger student-login-alumini align-middle" >ALUMNI </Link>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <h6> &nbsp; </h6>
                        <div className="d-flex justify-content-center">
                            <img src={magzine ? magzine.cover : YashoGatha} alt="YashoGatha" className="yashogatha" onClick={() => {
                                window.location.href = magzine ? magzine.url : 'http://files.vves.org.in/vves/magazine/Yashogatha_2023.pdf';
                            }} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

