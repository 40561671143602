import React from 'react'
import { getData } from '../../../../../api/AxisConfig'
import { getURL } from '../../../../../api/URL';
import { useState } from 'react'
import { useEffect } from 'react';
import parse from 'html-react-parser';

import "./ck-editor-based.layout.scss"

export const CkEditorBasedLayout = ({ section_data, institute_id }) => {

  const [data, setData] = useState("");

  useEffect(() => {
    const params = [institute_id]
    getData(getURL(section_data.data_url, "", params), (resp) => {
      if (resp.status == 200) {
        setData(resp.data.data);
        console.log(resp.data.data);
      }
    }, error => {
      console.log("Error :: " + error);
    });


  }, [section_data]);

  if (!data) {
    return <div>Loading data...</div>;
  }

  return (
    <div className="ck-editor">
      <div className='card shadow content'>
        <h6>{data.title}</h6>
        <hr />
        {(typeof data.content) == 'string' ? parse(data.content) : data.content}
      </div>
    </div>
  )
}
