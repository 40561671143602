import React from 'react'
import './page-header.scss'

export const PageHeaderComponent = ({ title }) => {
    return (
        <div className='page-header'>
            <div className='col-sm-12'>
                <header>
                    <h5>{title}</h5>
                </header>
            </div>
        </div>
    )
}
