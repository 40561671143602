import React from 'react'
import Moment from 'moment';
import { Image } from 'primereact/image';
import './student-achievements.scss'
import parse from 'html-react-parser';
import trophy from '../../assets/images/trophy.png';

export const StudentAchievmentsItemComponent = ({ achievement }) => {
    return (
        <div className='student-achievements-item col-sm-4'>
            <div className='card padding'>
                <div className='col-sm-12'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img src={trophy} className='ann-icon' />
                        </div>
                        <div className='col-sm-9'>
                            {achievement.file_url !== '' ? <Image src={achievement.file_url} zoomSrc={achievement.file_url} alt="View Image" imageClassName="img-fluid img-thumbnail" preview /> : <></>}
                            <h4 className='achiever-name'>{achievement.achiever_name}</h4>
                            <h6 className='achievement'>{achievement.achievement}</h6>
                            <h6>{(typeof achievement.achievement_desc) == 'string' ? parse(achievement.achievement_desc) : achievement.achievement_desc}</h6>
                            <h6>{achievement.achievement_type_code} {achievement.year_code}</h6>
                            <div className='create-dt'>
                                {Moment(achievement.created_at).format('DD/MM/yyyy')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
