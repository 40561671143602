import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { getData } from '../../../api/AxisConfig';
import { getURL } from '../../../api/URL';

import './institution-details-view.scss'
import { CkEditorBasedLayout } from './section-details/ck-editor-based/ck-edidtor-based.layout';
import { SERVICE_URL } from '../../../api/ServiceUrl';

import { AwardsLayout } from '../../awards';
import { StudentAchievementsLayout } from '../../student-achievments'
import { PdfViewerLayout } from './section-details/academic-calendar/pdf-viewer.layout';
import { GalleryGridLayout } from '../../gallery/gallery-grid';
import { HMPrincipalLayout } from './section-details/hm-principal/hm-principal.layout';
import { AwardsAchievementsLayout } from './section-details/awards-achievement/awards-achievement.layout';
import { SchoolInfrasturctureLayout } from './section-details/school-infrastructure/school-infrastructure.layout';
import { SchoolFacilitiesLayout } from './section-details/school-facilities/school-facilities.layout';


export const InstitutionDetailsViewLayout = () => {
    const { id } = useParams();
    const menuLayout = [
        { id: 0, 'menu_title': 'School Information', type: "CK-EDITOR", data_url: SERVICE_URL.SCHOOL_INFO.GET_SCHOOL_INFO },
        //{ id: 1, 'menu_title': 'Past Results', type: "CK-EDITOR", data_url: SERVICE_URL.PAST_RESULT.GET_PAST_RESULT },
        { id: 1, 'menu_title': 'Past Results', component:<PdfViewerLayout api="PAST_RESULT" endpoint="GET_PAST_RESULT" institute_id={id} />, type: "NON-CK-EDITOR"  },
        { id: 2, 'menu_title': 'HM/Principal Information', component: <HMPrincipalLayout institute_id={id} />, type: "NO-CK-EDITOR" },
        //{ id: 3, 'menu_title': 'PTA Members', type: "CK-EDITOR", data_url: SERVICE_URL.SCHOOL_PTA_MEMBER.GET_SCHOOL_PTA_MEMBER },
        { id: 3, 'menu_title': 'PTA Members', component:<PdfViewerLayout api="SCHOOL_PTA_MEMBER" endpoint="GET_SCHOOL_PTA_MEMBER" institute_id={id} />, type: "NON-CK-EDITOR"  },
        { id: 4, 'menu_title': 'Awards', component: <AwardsAchievementsLayout institute_id={id} achievement_type="teacher" />, type: "NON-CK-EDITOR" },
        { id: 5, 'menu_title': 'Contact Information', type: "CK-EDITOR", data_url: SERVICE_URL.SCHOOL_CONTACT.GET_SCHOOL_CONTACT },
        { id: 6, 'menu_title': 'Academic Calendar', component: <PdfViewerLayout api="ACADEMIC_CALENDAR" endpoint="GET_ACADEMIC_CALENDAR" institute_id={id} />, type: "NON-CK-EDITOR" },
        { id: 7, 'menu_title': 'Scholarships', type: "CK-EDITOR", data_url: SERVICE_URL.SCHOLARSHIP.GET_SCHOLARSHIP },
        { id: 8, 'menu_title': 'Activities Offered', type: "CK-EDITOR", data_url: SERVICE_URL.SCHOOL_ACTIVITY.GET_SCHOOL_ACTIVITY },
        { id: 9, 'menu_title': 'Infrastructure Offered', component: <SchoolInfrasturctureLayout institute_id={id} />, type: "NON-CK-EDITOR" },
        { id: 10, 'menu_title': 'Other Activities', type: "CK-EDITOR", data_url: SERVICE_URL.SCHOOL_ACTIVITY.OTHER_ACTIVITY },
        { id: 11, 'menu_title': 'Facilities Offered', component: <SchoolFacilitiesLayout institute_id={id} />, type: "NON-CK-EDITOR" },
        { id: 12, 'menu_title': 'Student Achievements', component: <AwardsAchievementsLayout institute_id={id} achievement_type="student" />, type: "NON-CK-EDITOR" },
        { id: 13, 'menu_title': 'Gallery', component: <GalleryGridLayout institute_id={id} type='section' />, type: "NON-CK-EDITOR" },
        { id: 14, 'menu_title': 'Admission Information', type: "CK-EDITOR", data_url: SERVICE_URL.ADMISSION_INFO.GET_ADMISSION_INFO },
    ]


    const [institute, setInstitute] = useState({});
    const [selectedItem, setSelectedItem] = useState(0);
    const [selectedSection, setSelectedSection] = useState(menuLayout[0]);
    const isPageLoaded = useRef(false);

    useEffect(() => {

        if (isPageLoaded.current) return;
        isPageLoaded.current = true;

        const params = [id];
        getData(getURL("GET_ALL_INSTITUTIONS", "INSTITUTIONS", params), (resp) => {
            if (resp.data.success == true) {
                setInstitute(resp.data.data);
            }

        }, error => {
            console.log("Error ::" + error.message);
        });


    }, []);

    if (!institute) {
        return <div>Loading data...</div>;
    }




    return (
        <div className='ins-details-view'>
            <div className="container1 container">
                <h1><strong className="bold">{institute.name}
                    <span className="address-txt"><br />{institute.address}</span>
                </strong> </h1>

                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="left-sec-nav-bar">
                                <nav>
                                    <ul className="mcd-menu">
                                        {menuLayout.map((section, index) => {
                                            return <li key={section.id} className='shadow-menu'>
                                                <a className={index == selectedItem ? 'active' : ''} onClick={() => {
                                                    setSelectedItem(index);
                                                    setSelectedSection(menuLayout[index])

                                                    if (section.subtype != null && section.subtype === 'EXTERNAL-URL') {

                                                    }
                                                }}>
                                                    <strong>{section.menu_title}</strong>
                                                </a>
                                            </li>

                                        })}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-sm-9">
                            <div className="right-sec card shadow">
                                {
                                    (selectedSection.type == "CK-EDITOR") ?
                                        <CkEditorBasedLayout section_data={selectedSection} institute_id={id} /> : selectedSection.component
                                }
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}
