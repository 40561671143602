import React from 'react'
import { Outlet } from 'react-router-dom';
import { InstitutionsLayout } from '../../layouts/institutions'

export const InstitutionsPage = () => {
    return (
        <div>
            <InstitutionsLayout />
            <Outlet />
        </div>
    )
}


