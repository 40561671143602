import React from 'react'
import { FooterLinksComponent } from '../../components/footer-links';
import { FooterMainComponent } from '../../components/footer-main';
import './footer.scss';

export const FooterLayout = () => {
    return (
        <div className='footer'>
            <FooterMainComponent />
            <FooterLinksComponent />
        </div>
    )
}