import React, { useEffect, useRef, useState } from 'react'
import { PageHeaderComponent } from '../../components/page-header'
import { RTIComponent } from '../../components/rti'
import { getData } from '../../api/AxisConfig'
import { getURL } from '../../api/URL'

import './rti.scss'
export const RTILayout = () => {

    const [rti, setRti] = useState([]);
    const dataFetchedRef = useRef(false);

    useEffect(() => {

        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        getData(getURL('GET_ALL_RTI', 'RTI'), (resp) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                console.log(resp.data.data);
                setRti(resp.data.data);
            }

        }, (err) => {
            console.log(err);
        })
    }, []);

    if (!rti) {
        return <div>Loading data...</div>;
    }

    return (
        <div className='rti-layout'>
            <PageHeaderComponent title="Right To Information" />
            {rti.map(item => {
                return <RTIComponent info={item} key={item.id} />
            })}

        </div>
    )
}
