import React from 'react'
import { ContactusLayout } from '../../layouts/contact-us/contactus.layout'

export const ContactusPage = () => {
    return (
        <div><ContactusLayout /></div>
    )
}


