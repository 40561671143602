import React from 'react'
import './contactus.layout.scss'
import { PageHeaderComponent } from '../../components/page-header'

export const ContactusLayout = () => {

    return (
        <div className="container">
            <PageHeaderComponent title="Contact us" />
            <div className=' contactus'>
                <div className='col-sm-12'>
                    <div className='row'>

                        <div className='col-sm-12'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className='card'>
                                        <h5>1.  Vidya Vikas Education Society</h5>
                                        School & College Campus,<br />
                                        Kannamwar Nagar - 2,<br />
                                        Vikhroli (East) Mumbai - 400083<br />

                                        Tele : 022- 25783540 / 25784267<br />
                                        Email : vves.vikhroli@gmail.com<br />
                                        Website : www.vves.org.in<br />


                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='card'>
                                        <h5>2.    Swami Shamanand Education Society</h5>
                                        Barve Nagar,<br />
                                        Ghatkopar (West)<br />
                                        Mumbai - 400083<br />

                                        Tele : 022- 25783540 / 25784267<br />
                                        Email : sses.ghatkopar@gmail.com<br />
                                        Website : www.vves.org.in<br />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
