import React from 'react'
import { Link } from 'react-router-dom';
import './footer-links.scss'
export const FooterLinksComponent = () => {
    return (
        <div className="footer-links">
            <div className="container">
                <div className="row">
                    <div className='footer-btn-group'>
                        <Link type="button" className="btn btn-primary btn-style" to="/rti">RIGHT TO INFORMATION</Link>
                        <Link type="button" className="btn btn-primary btn-style" to="/notices">NOTICES</Link>
                        <Link type="button" className="btn btn-primary btn-style" to="/mediatalks" >MEDIA TALKS</Link>
                        <Link type="button" className="btn btn-primary btn-style" to="/grievances">GRIEVANCES & COMPLAINTS</Link>
                        <Link type="button" className="btn btn-primary btn-style" to="/importantlinks">IMPORTANT LINKS</Link>
                    </div>
                </div>
            </div>

        </div>
    )
}
